import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Router } from '@angular/router';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ErrorService } from 'src/app/shared/services/http/error.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [
    MatProgressBarModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatCardModule,
    SharedModule,
  ],
})
export class LoginComponent implements OnInit {
  public formGroup!: FormGroup;
  public loading: boolean = false;

  constructor(
    private errorService: ErrorService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.initializeForm();
  }

  /**
   * initailize login form
   */
  private initializeForm(): void {
    this.formGroup = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', Validators.required],
    });
  }

  /**
   * send credentials to server
   */
  public login() {
    this.loading = true;
    this.authService.login(this.formGroup.value).subscribe({
      next: () => {
        this.router.navigate(['dashboard/customers'], { replaceUrl: true });
      },
      error: (error) => {
        this.loading = false;
        this.errorService.showMessage(error?.message || error);
      },
    });
  }
}
