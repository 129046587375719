<div class="login-container" fxLayout="row">
  <div class="login-image" fxLayoutAlign="center center" fxFlex fxHide.lt-md>
    <img src="./assets/images/logo.png">
  </div>
  <div class="login-form-wrapper" fxLayoutAlign="center center" fxFlex>
    <mat-card fxFlex="75">
      <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
      <form fxLayout="column" fxLayoutGap="2rem" fxLayoutAlign="center center" [formGroup]="formGroup">
        <mat-form-field>
          <mat-label>{{ "username" | translate }}</mat-label>
          <input type="text" formControlName="username" matInput>
          <mat-error>{{ "username" | translate }} {{ "is_required" | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ "password" | translate }}</mat-label>
          <input type="password" formControlName="password" matInput>
          <mat-error>{{ "password" | translate }} {{ "is_required" | translate }}</mat-error>
        </mat-form-field>

        <button class="btn-height" mat-stroked-button color="primary" [disabled]="formGroup.invalid || loading"
          (click)="login()">
          {{ "login" | translate }}
        </button>
      </form>
    </mat-card>
  </div>
</div>
