import { Component, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SharedModule } from '../../modules/shared.module';

@Component({
  selector: 'app-loader',
  templateUrl: './app-loader.component.html',
  styleUrls: ['./app-loader.component.scss'],
  standalone: true,
  imports: [MatProgressSpinnerModule, SharedModule],
})
export class AppLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }
}
